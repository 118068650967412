<template>
  <div class="not-found">
    <h1>Not Found</h1>
    <div>Sorry, the requested resource has not been found. </div>
  </div>
</template>

<script>
export default {
  name: 'Help'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.not-found{
  text-align: center;
  justify-content: center;
  margin: 1rem auto;
}


</style>
